// ----------------------------------------------------------
// =Print styles - Override colours to black
// ----------------------------------------------------------
@media print{
		$print-colour: #000000;

		// Loop through headings h1 - h6
		@for $i from 1 through 6 {
			h#{$i} {
				color: $print-colour !important;
			}
		}

		body,
		textarea,
		input {
			font-size: 12pt !important;
		}

		body,
		textarea,
		input,
		a,
		a:visited {
			color: $print-colour !important;
			line-height: 1.4;
		    word-spacing: 1.1pt;
		    letter-spacing: 0.2pt; 
		    font-family: Garamond,"Times New Roman", serif !important;
		}

		ul, 
		ol,
		p { 
			margin-bottom: 20px; 
		}

		ul li {
			list-style-type: disc; 
			margin-left: 20px;
		}

		ol li {
			list-style-type: decimal; 
			margin-left: 20px;
		}

		.listing a:link[href^="http://"]:after {
			display: none;
		}

		code { 
			font: 10pt Courier, monospace; 
		}

		blockquote { 
			margin: 1.3em; 
			padding: 1em;
			font-size: 10pt;
		}

		hr { 
			border-top: none; 
			border-right: none; 
			border-left: none; 
			border-bottom: 2px solid #cccccc;
		}

		strong { 
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		a:link, 
		a:visited { 
			background: transparent; 
			font-weight: 700; 
			text-decoration: underline;
			color: #333; 
			word-wrap: break-word;
		}

		a:link[href^="http://"]:after,
		a[href^="http://"]:visited:after { 
			content: " (" attr(href) ") ";
			font-size: 90%;
		}

		a[href^="http://"] {
			color: $print-colour;
		}

		table { 
			margin: 1px; 
			text-align:left;
		}

		th { 
			border-bottom: 1px solid #333;
			font-weight: bold;
		}

		td { 
			border-bottom: 1px solid #333;
		}

		th,
		td { 
			padding: 4px 10px 4px 0;
		}

		tfoot {
			font-style: italic;
		}

		caption {
			background: #fff; 
			margin-bottom: 2em; 
			text-align: left;
		}

		thead {
			display: table-header-group;
		}

		tr {
			page-break-inside: avoid;
		}
		.search__input-home,.header__search-input,.co__gallery-list,.co__gallery-title,.co .scene-noanimation,.google_map--container,.ma__box,.footer-scene{
			display: none!important;
			height: 0!important;
		}
		.scene-noanimation{
			height: 0;
		}
		.events,.second-text {
			height: auto; 
			min-height: 0;
		}
		.header,.scene-noanimation{
			background: none;
			height: auto;
		}

}